.split-container {
  display: flex;
  width: 100%;
  height: 100vh; /* Adjust the height as needed */
}

.panel {
  flex-grow: 1;
  overflow: auto; /* Add scrollbars if content overflows */
}

.resizer {
  cursor: col-resize;
  background-color: #ccc;
  width: 10px;
  height: 100%;
}
/* Add this to your split.css or equivalent */
.resizable-handle {
  background-color: #e0d1d1; /* Or any color that stands out */
  width: 1px; /* Adjust the width as needed */
  height: 100%;
}

/* Ensure the handle is visible on hover or always, depending on your preference */
.re-resizable:hover .resizable-handle,
.resizable-handle {
  opacity: 1; /* Adjust based on your preference */
}