/* DeckSplitPanel.css */

.split {
  display: flex;
  height: 100%; /* Ensure the Split component takes the full viewport height */
  width: 100%; /* Ensure the Split component takes the full viewport width */
}

.split .gutter {
  background-color: #000;
  background-repeat: no-repeat;
  background-position: 50%;
}

.split .gutter:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.split .gutter-horizontal {
  cursor: col-resize;
  width: 10px; /* Ensure the gutter has sufficient width for dragging */
}
